import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, DatePicker, Modal, Space, Spin, Tooltip, message, Tag  } from 'antd';
import { useHistory, useParams } from 'react-router';
import { useQuery, QueryStatus } from "react-query";
import { deleteAlert, fetchAlert, getAMEApprovalStatus, getAlertPRStatus, runPastAlert, switchAlert } from '@utils/helpers';
import { LoadingOutlined } from '@ant-design/icons';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import {AxiosError} from 'axios';
import AlertForm from '@components/AlertForm/AlertForm';
import { useMsal } from '@azure/msal-react';
import { AlertState, AlertPRStatus} from '../../constants/Enums';
import { WAWS_BASE_CONFIG } from '@constants/general';
import moment from 'moment-timezone'
import { CustomizedTooltip } from '@components/common';

const { confirm } = Modal;

type Props = {
};

type IdParams = {
    id : string;
}



const ExistingAlert: React.FC<Props> = () => {
    const [editMode, setEditMode] = useState(false);
    const { accounts, instance } = useMsal();

    let { id } = useParams<IdParams>();
    let history = useHistory();

    const { status, error, data } = useQuery<IAlert, AxiosError>(['alert', id], () =>  fetchAlert(id, accounts, instance) );
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [showModal, setShowModal] = useState(false);
    const [pastTime, setPastTime] = useState<any>();
    const [switchLoad, setSwitchLoad] = useState(false);

    const [AMEApprovalStatus, setAMEApprovalStatus] = useState<string>("ApprovalPending");
    const [PRStatus, setPRStatus] = useState<number>(0);

    useEffect(() => {
      if( data != null )
        {
          if(showPendingAlertNotification(data)){
            fetchPRStatus(data);

            if(checkIfAlertIsAntaresGenevaAlert(data)){
              fetchAMEApprovalStatus(data);
            } 
          }

        }

    }, [data]); // data is alert


    const handleDeleteAlert = () => {
        showConfirm();
    };

    const handleEditAlert = () => {
        setEditMode(true);
    };

    const handleCancelEditAlert = () => {
        setEditMode(false);
    }

    const sendTestPast = (dateString, alertId) => {
      let ts = moment(dateString);
      runPastAlert(accounts, instance, alertId, ts.unix());
      message.success(`Test alert report will be sent to ${accounts[0].username}`);
    }

    const onModalOk = (alertId) => {
      sendTestPast(pastTime, alertId)
      setShowModal(false);
    }

    const disabledDate = (current) => {
      // Can not select days before today and today
      return !(current < moment().endOf('day').subtract(0, 'day') && current > moment().subtract(30, 'day'));
    }

    const renderOpenNewTab = (uri) => {
      window.open(uri);
    }

    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        message.success("Copied to clipboard successfully!");
      }, (err) => {
        // This is where you handle any errors
        message.error("Failed to copy: ", err);
      });
    };


    const redirectToChangeLog = (alert) => {
      let baseUri = alert.prLink;
      if (baseUri === undefined || baseUri === null) {
        baseUri = WAWS_BASE_CONFIG['BaseDevOpUri']
      }
      var re = /\/pullrequest\/[0-9]+/gi;
      baseUri = baseUri.replace(re, '');
      let svcName = alert.serviceName.replace(/ /g, '_');
      window.open(`${baseUri}?path=/alerts/${svcName}/${alert.id}.json`)
    }

    function showConfirm() {
        confirm({
          title: 'Are you sure you want to delete this alert?',
          icon: <ExclamationCircleOutlined />,
          async onOk() {
            await deleteAlert(accounts, instance, id);
            history.push('/');
          },
          onCancel() {
            console.log('Cancel');
          },
        });
    }

    function checkIfAlertIsAntaresGenevaAlert(alert: IAlert): boolean {
      return alert.actions?.some(action => action.genevaOperation?.extension === "Antares") ?? false;
    }

    function showPendingAlertNotification(alert: IAlert): boolean {
      return alert.alertState !== AlertState.Active && alert.alertState !== AlertState.Disabled;
    } 


    async function fetchPRStatus(alert: IAlert) {
      if(typeof alert.prLink === 'undefined' || alert.prLink === null || alert.prLink === "") {
        message.error("PR link is not available, please contact antmonchamp@microsoft.com for help.")
      }else{
        let res = await getAlertPRStatus(accounts, instance, id, alert.prLink);
        if (res.status === 200) {
          setPRStatus(res.data);
        } else {
          message.error(`Failed to fetch PR status: ${res.data}, please contact antmonchamp@microsoft.com for help.`);
        }
      }
    }

    async function fetchAMEApprovalStatus(alert: IAlert) {
      if(typeof alert.approvalDocumentId === 'undefined' || alert.approvalDocumentId === null || alert.approvalDocumentId === "") {
        message.error("AME Approval link is not available, please contact antmonchamp@microsoft.com for help.")
      }else{
        let res = await getAMEApprovalStatus(accounts, instance, id, alert.approvalDocumentId);
        if (res.status === 200) {
          setAMEApprovalStatus(res.data.status);
        } else {
          message.error(`Failed to fetch AME Approval link status: ${res.data}, please contact antmonchamp@microsoft.com for help.`);
        }
      }
    }


    const renderPendingAlertNotification = (alert: IAlert) => {

      function getEnumKeyByValue(enumObj: any, value: number): string | undefined {
        return Object.keys(enumObj).find(key => enumObj[key] === value);
      }

      const antaresCloudTactic = {
        "Nitasha Verma": "nitasha.verma@microsoft.com",
        "Bilal Alam": "balam@microsoft.com",
        "Ben Byrd": "Ben.Byrd@microsoft.com",
        "Sergey Kratovich": "Sergey.Kratovich@microsoft.com",
        "Jaroslav Dunajsky": "jaroslad@microsoft.com",
        "Stefan Schackow": "stefsch@microsoft.com",
        "Kranthi Kumar Mannem": "kranthim@microsoft.com",
        "Karl Reinsch": "Karl.Reinsch@microsoft.com",
        "Praveen Babu Tirumala": "Praveen.T@microsoft.com",
        "Zhe Yang": "Zhe.Yang@microsoft.com",
        "Hamid Safi": "hamids@microsoft.com",
        "Galin Iliev": "Galin.Iliev@microsoft.com",
        "Jenny Lawrance": "jennylaw@microsoft.com",
        "Suwat Bodin": "suwatch@microsoft.com",
        "Phil Luo": "Phil.Luo@microsoft.com",
        "Joaquin Vano": "Joaquin.Vano@microsoft.com",
        "Ruslan Yakushev": "ruslany@microsoft.com",
        "Thad Kittelson": "thadk@microsoft.com",
        "Navy Aziz": "naveeda@microsoft.com",
        "Pragna Gopa": "pgopa@microsoft.com",
        "Pavel Zelinsky": "pavelzel@microsoft.com",
        "Wei Zhao (ANTARES)": "wzhao@microsoft.com",
        "Jaiprakash Sharma": "jsharma@microsoft.com",
        "Vik Raheja": "vikr@microsoft.com",
        "Surender Singh Malik": "sumali@microsoft.com",
        "Petr Podhorsky": "petrpod@microsoft.com",
        "Paul Batum": "pbatum@microsoft.com",
        "Steve Ernst": "sterns@microsoft.com",
        "James Christianson": "jamchri@microsoft.com",
        "Radhika Bollineni": "rabollin@microsoft.com",
        "Mitali Karmarkar": "Mitali.Karmarkar@microsoft.com",
        "Nick Craver": "nickcraver@microsoft.com"
      };
    

      return (
        <Alert type="info" showIcon
        message= {<div>The current alert State is <b>{getEnumKeyByValue(AlertState, alert.alertState)}</b>.</div>}
        description={<Space direction='vertical'>
        <span>Your recent changes haven't been synced. Please complete the required approval process. If you encounter any issues, please contact <a href="mailto:antmonchamp@microsoft.com">Antares Monitoring Champs</a>.</span>
        {
            checkIfAlertIsAntaresGenevaAlert(alert)?
            <div><b>Your service requires all Geneva alerts to have PR approval from Antares Cloud Tactics and AME approval. </b> 
            If your alert is not approved by these groups, it will remain in a pending state and the previous version will continue to be executed.
            
            <ul>
              <li><Space><b>PR Approval Status: <Tag>{getEnumKeyByValue(AlertPRStatus, PRStatus)}</Tag></b></Space>
                <ul>
                  <li>Who can approve the PR? {CustomizedTooltip("Antares Cloud Tactics",
                    <div>
                      {Object.keys(antaresCloudTactic).map((key, index) =>
                        <span key={index}>{key}; </span>
                      )}
                    </div>
                  )}<Button type="primary" size='small' style={{marginLeft: "10px"}} onClick={() => copyToClipboard(alert.prLink)}>Copy PR Link</Button></li>
                </ul>
              </li>
              <li><Space><b>AME Approval Status: <Tag>{AMEApprovalStatus}</Tag></b></Space>
                <ul>
                  <li>Who can approve the approval link?  {CustomizedTooltip("AME\\\AD-ANTARES;AME\\\AP-Antares", <span>This is the group who has access to approve Antares JIT/release pipeline.</span>)} using <b>SAW</b>
                  <Button type="primary" size='small' style={{marginLeft: "10px"}} onClick={() => copyToClipboard(alert.approvalDocumentUri)}>Copy AME Approval Link</Button></li>
                  <li>Note: The approval link will be automatically rejected after 5 days. If rejected, your alert will remain in a pending state and the previous version will continue to be executed.</li>
                  </ul>
              </li>

            </ul>
            </div>:
            <Space>
             <b>PR Approval Status: <Tag>{getEnumKeyByValue(AlertPRStatus, PRStatus)}</Tag></b><Button type="primary" size='small' onClick={() => copyToClipboard(alert.prLink)}>Copy PR Link</Button>
            </Space>
          }
    </Space>
      }
      ></Alert>
      )
    }

    const renderAlertStatusBanner = (alert : IAlert ) =>
    {
      const sendTestRun = () => {
        runPastAlert(accounts, instance, alert.id);
        message.success(`Test run report will be sent to ${accounts[0].username}`);
      }
      const showEnableDisable = () => {
        if (alert.alertState===AlertState.Active) {
          return "Disable"
        }
        return "Enable"
      }
      const switchAlertState = async () => {
        setSwitchLoad(true);
        let option:string;
        if (alert.alertState === AlertState.Active) {
          option = AlertState.Disabled.toString();
        } else {
          option = AlertState.Active.toString();
        }
        let resp = await switchAlert(accounts, instance, alert.id, option);      
        if (resp.status !== 200) {
          message.error(`Failed update alert state, ${resp.data}`, 10)
        } else {
          message.success("Alert state updated", 10)
          setTimeout(() => {
            window.location.reload();
          }, 5000); 
        }
        setSwitchLoad(false);
      }
      
      let hasMachineAction = false;
      let hasGenevaAction = false;

      alert.actions?.forEach(action=>{
        if ('actionType' in action){
          hasMachineAction = true;
        }
        else if ('genevaOperation' in action){
          hasGenevaAction = true;
        }      
      })

      let hasAMEApprovalPending = alert.approvalDocumentId !== null && alert.approvalDocumentId !== undefined && alert.approvalDocumentId !== "";

        return (
            <div>
                {/* {(alert.alertState !== AlertState.Active && alert.alertState !== AlertState.Disabled) && 
                    // <Alert
                    // message="This alert is currently unable to be edited as there is an active PR. The alert below is the state of the active alert (before the PR changes)"
                    // description={<a href={alert.prLink} target="_blank" rel="noopener noreferrer">Open Pull Request</a>}
                    // type="warning"
                    // showIcon
                    // />
                } */}
                <Space wrap>
                <Button type="primary" onClick={handleEditAlert}>
                      Edit Alert
                    </Button>
                    <Button type="primary"
                      disabled={!(alert.alertState===AlertState.Active||alert.alertState===AlertState.Disabled)}
                      loading={switchLoad}
                      onClick={switchAlertState}>
                      {showEnableDisable()}
                    </Button>
                    <Button danger onClick={handleDeleteAlert}>
                      Delete Alert
                    </Button>
                    <Button type="primary" onClick={sendTestRun}>
                      Test Run
                    </Button>
                    <Button type="primary" onClick={()=>setShowModal(true)} disabled={alert.alertState!==AlertState.Active}>
                      Test Past
                    </Button>
                    <Button type="primary" onClick={()=>renderOpenNewTab(`/history/?serviceId=${alert.serviceId}&alertId=${alert.id}`)}>
                      View History
                    </Button>
                    {hasMachineAction? <Button type="primary" onClick={()=>renderOpenNewTab(`/machineactionhistory/${alert.id}`)}>
                      Machine Action History
                    </Button> : ""}
                    {hasGenevaAction? <Button type="primary" onClick={()=>renderOpenNewTab(`/genevaactionhistory/${alert.id}`)}>
                      Geneva Actions History
                    </Button> : ""}
                    <Button type="primary" disabled={!hasAMEApprovalPending} onClick={()=>renderOpenNewTab(alert.approvalDocumentUri)}>
                      AME Approval Link (Requires SAW)
                    </Button>
                    <Button type="primary" onClick={()=>renderOpenNewTab(alert.prLink)}>
                      PR Link
                    </Button>
                    <Button type="primary" onClick={()=>redirectToChangeLog(alert)}>
                      Change Log
                    </Button>
                    <Modal visible={showModal} onOk={()=>onModalOk(alert.id)} onCancel={()=>setShowModal(false)}>
                      <p>Select a UTC time within 30 days to test alert in the past</p>
                        <DatePicker
                          showNow={false}
                          format="YYYY-MM-DD HH:mm:ss"
                          disabledDate={disabledDate}
                          showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                          onChange={(val, dateString)=>setPastTime(dateString)}
                        />
                    </Modal>
                </Space>
                <div style={{width: "92%", marginTop:"20px", marginBottom: "20px"}}>
                  <div style={{width: "92%"}}>{showPendingAlertNotification(alert)?renderPendingAlertNotification(alert):<></>}</div>
                </div>

            </div>
        )
    }

    const renderAlertCancelEdit = (alert) => 
    {
        return (
            <Space wrap>
              <Button type="primary" onClick={handleCancelEditAlert}>
                Cancel Editing
              </Button>
              <Button type="primary" onClick={()=>renderOpenNewTab(`/history/?serviceId=${alert.serviceId}&alertId=${alert.id}`)}>
                View History
              </Button>
              <Button type="primary" onClick={()=>renderOpenNewTab(alert.prodPipelineLink)}>
                AME Approval Link (Requires SAW)
              </Button>
              <Button type="primary" onClick={()=>renderOpenNewTab(alert.prLink)}>
                PR Link
              </Button>
              <Button type="primary" onClick={()=>redirectToChangeLog(alert)}>
                Change Log
              </Button>
            </Space>
        )
    }

    const renderAlert = (alert : IAlert) : JSX.Element => {
        return(
            <div>
                {(editMode === false) && renderAlertStatusBanner(alert)}
                {(editMode === true) && renderAlertCancelEdit(alert)}
                <AlertForm isNewAlert={false} isDisabled={!editMode} existingAlert={alert} setEdit={setEditMode}/>
            </div>
        )
    }


    const statusRender = (status : QueryStatus) : JSX.Element => {

        switch(status)
        {
            case "error":
                return (
                    <Card>
                        <Alert
                        message="Error"
                        description={error?.message}
                        type="error"
                        showIcon
                        />
                    </Card>
                );
            case "idle":
                return (
                    <Card>
                        <Spin indicator={antIcon} />
                    </Card>
                );
            case "loading":
                return (
                    <Card>
                        <Spin indicator={antIcon} />
                    </Card>
                );
            case "success":
                if (data === undefined)
                {
                    return (
                        <Card>
                            <Alert
                            message="Error"
                            description="Data does not exist for this alert..."
                            type="error"
                            showIcon
                            />
                        </Card>
                    );
                } else {
                    return (
                        <Card title={data.title}>
                            {renderAlert(data)}
                        </Card>
                    );
                }
        }
        // below ensures that all of the statuses are taken care of in the above switch
        ((x: never) => {})(status);
    }

    return (
        <>
        <div style={{margin:"2%"}}>
            {statusRender(status)}
        </div>
        </>
    );
};

export default ExistingAlert;
