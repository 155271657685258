import { InteractionRequiredAuthError, IPublicClientApplication } from "@azure/msal-browser";
import { AccountInfo } from "@azure/msal-common";
import { AZURE_DEVOPS_SCOPE, WAWS_ALERTING_API } from "@constants/general";
import Axios from "axios";

export const sum = (a: number, b: number): number => a + b;

function IsAirgapHost(): boolean {
    const airgapHosts = [
        ".eaglex.ic.gov",
        ".microsoft.scloud"
    ]

    return airgapHosts.some(suffix => window.location.host.includes(suffix));
  }

const GetAccessToken = async (accounts: AccountInfo[], instance: IPublicClientApplication): Promise<string> => {
    let accessToken = "";
    if (accounts.length > 0) {
        let request: {account: AccountInfo, scopes: string[]} = {
            account: accounts[0],
            scopes: []
            //loginResource: 'https://management.core.windows.net/'
        };


        if (!IsAirgapHost()) {
            /*
            This scope does not exist in airgapped clouds, so we use a service account to auth to ADO instead.
            Only add it if we are not in an airgapped cloud
            */
            request.scopes = [AZURE_DEVOPS_SCOPE];
        }

        await instance.acquireTokenSilent(request).then(response => {
            accessToken = response.accessToken
        }).catch(error => {
            // acquireTokenSilent can fail for a number of reasons, fallback to interaction
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenPopup(request).then(response => {
                    accessToken = response.accessToken
                });
            }
        });
    }
    return accessToken;
}


// Send GET request for all alerts in HOME
export const fetchAlerts = async (accounts: AccountInfo[], instance: IPublicClientApplication, page, limit: number, keyword: string, myAlert:boolean, serviceId?: string, tags?: string[], alertState?: number[], actionType?: string) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    let email = accounts[0].username;
    const res = Axios.get<IAlert[]>(`${WAWS_ALERTING_API}/alerts`,
        { 
          params: { 'token': accessToken, 'alias': emailToAlias(email), 'keyword': keyword, 'page': page, 'limit': limit, 'myAlert': myAlert, 'serviceId': serviceId, 'tags': JSON.stringify(tags), 'state': JSON.stringify(alertState), 'actionType': actionType },
          headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};

// need merge with above
export const fetchAlerts2 = async (accounts: AccountInfo[], instance: IPublicClientApplication, queryParam: QueryParams) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    let email = accounts[0].username;
    const res = Axios.get<IAlert[]>(`${WAWS_ALERTING_API}/alerts`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'keyword': queryParam.keyword, 'page': queryParam.page, 'limit': queryParam.limit, 'myAlert': queryParam.mineAlert, 'serviceId': queryParam.serviceId, 'sort': queryParam.sort },
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};

export const fetchRecentAlertsHistory = async (accounts: AccountInfo[], instance: IPublicClientApplication, count: string, state: string | null, timeSpan: string | null, timeStamp: string | null) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/recentalertsrunhistory`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'count': count, 'state': state, 'timespan': timeSpan, 'timestamp': timeStamp },
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};

export const fetchAlertHistoryById = async (accounts: AccountInfo[], instance: IPublicClientApplication, alertId: string, state: string | null, timeSpan: string | null, timeStamp: string | null, count: string) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/alerthistory/${alertId}`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'state': state, 'timespan': timeSpan, 'timestamp': timeStamp, 'count': count },
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};

export const fetchActionHistoryById = async (accounts: AccountInfo[], instance: IPublicClientApplication, actionId: string) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/actionhistory/${actionId}`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email) },
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};

export const fetchAlertHistoryRawLogById = async (accounts: AccountInfo[], instance: IPublicClientApplication, alertId: string | null, actionId: string | null) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/alertrawlog/${alertId}/${actionId}`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email) },
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};

export const fetchEnd2EndTestEnquer = async (accounts: AccountInfo[], instance: IPublicClientApplication) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/end2endtestenquer`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'userprincipal': email },
        headers:{'Authorization': bear} })
        .then((response) => { return response });
    return res
}

export const fetchEnd2EndTestAlertHistory = async (accounts: AccountInfo[], instance: IPublicClientApplication, enqueuedTimeStamp: string | null) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/end2endtesttrack`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'userprincipal': email, 'enqueuedtimestamp': enqueuedTimeStamp },
        headers:{'Authorization': bear} })
        .then((response) => { return response });
    return res
};

export const fetchEnd2EndTestRecords = async (accounts: AccountInfo[], instance: IPublicClientApplication, timespan: string | null) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/end2endtestrecords`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'userprincipal': email, 'timespan': timespan },
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
}

export const fetchEnd2EndTestAuth = async (accounts: AccountInfo[], instance: IPublicClientApplication) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/end2endtestauth`,
        { params: { 'token': accessToken, 'userprincipal': email },
          headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
}

export const fetchAlertOverallChart = async (accounts: AccountInfo[], instance: IPublicClientApplication, days: string | null) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/alertoverallchart`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'days': days },
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};

export const fetchAlertOverallStatisticsChart = async (accounts: AccountInfo[], instance: IPublicClientApplication, timeRange: string | null) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/alertoverallstatisticschart`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'timerange': timeRange},
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};


export const fetchAlertStatisticsChart = async (accounts: AccountInfo[], instance: IPublicClientApplication, alertId: string | null, timeRange: string | null, type: string | null) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/alertstatisticschart/${alertId}`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'timerange': timeRange, 'type': type },
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};

export const fetchMachineActionOverallChart = async (accounts: AccountInfo[], instance: IPublicClientApplication, timeRange: string | null) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/machineactionoverallchart`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'timerange': timeRange},
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};

export const fetchGenevaActionsOverallChart = async (accounts: AccountInfo[], instance: IPublicClientApplication, timeRange: string | null) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/genevaactionoverallchart`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'timerange': timeRange},
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};

export const fetchMachineActionHistory = async (accounts: AccountInfo[], instance: IPublicClientApplication, alertId: string | null, count: string | null, state: string | null, timeSpan: string | null, timeStamp: string | null) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/machineactionhistory/${alertId}`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'count': count, 'state': state, 'timespan': timeSpan, 'timestamp': timeStamp },
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};

export const fetchMachineActionAlerts = async (accounts: AccountInfo[], instance: IPublicClientApplication, alias: string | null, isMyAlter: string| null, sort: string| null) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/machineactionalerts`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'myAlter': isMyAlter, 'sort': sort },
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};

export const fetchGenevaActionHistory = async (accounts: AccountInfo[], instance: IPublicClientApplication, alertId: string | null, count: string | null, state: string | null, timeSpan: string | null, timeStamp: string | null) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/genevaactionhistory/${alertId}`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'count': count, 'state': state, 'timespan': timeSpan, 'timestamp': timeStamp },
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};

export const fetchGenevaActionAlerts = async (accounts: AccountInfo[], instance: IPublicClientApplication, alias: string | null, isMyAlter: string| null, sort: string| null) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/genevaactionalerts`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'myAlter': isMyAlter, 'sort': sort },
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};

export const fetchAdminCommandAlerts = async (accounts: AccountInfo[], instance: IPublicClientApplication, alias: string | null, isMyAlter: string| null, sort: string| null) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/kustoadmincommandalerts`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'myAlter': isMyAlter, 'sort': sort },
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};

export const getNumberOfAlertsByMonth = async (accounts: AccountInfo[], instance: IPublicClientApplication) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/numberofalertsbymonth`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email)},
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};

export const fetchGenevaActionGroupOpts = async (accounts: AccountInfo[], instance: IPublicClientApplication, extension: string, serviceId: string) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/genevaactions/getgroupoperations/${extension}`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'serviceid': serviceId},
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};

export const getGenevaActionRequest = async (accounts: AccountInfo[], instance: IPublicClientApplication, extensionName: string, operation: string, serviceId: string) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    const res = Axios.get(`${WAWS_ALERTING_API}/genevaactions/getrequesttemplate/${extensionName}/${operation}`,
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'serviceid': serviceId},
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res
};

export const getGenevaActionExtensions = async (accounts: AccountInfo[], instance: IPublicClientApplication, serviceId: string) => {
  let accessToken = await GetAccessToken(accounts, instance);
  let bear = `Bearer ${accessToken}`
  let email = accounts[0].username;
  let res = Axios.get(`${WAWS_ALERTING_API}/genevaactions/getgenevaextensions`, 
        { params: { 'token': accessToken, 'alias': emailToAlias(email), 'serviceid': serviceId},
        headers:{'Authorization': bear} })
        .then((response) => { return response.data });
    return res 
};

export const addRoleBasedClaims = async (accounts: AccountInfo[], instance: IPublicClientApplication, tenant: string, scope: string, role: string, serviceId: string) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`
    let email = accounts[0].username;
    let res = Axios.get(`${WAWS_ALERTING_API}/genevaactions/addrolebasedclaims`, 
          { params: { 'token': accessToken, 'alias': emailToAlias(email),'tenant': tenant, 'scope': scope, 'role': role, 'serviceid': serviceId},
          headers:{'Authorization': bear} })
          .then((response) => { return response })
            .catch((error)=>{ return error.response });
    return res 
};


export const createAMEApprovalLink = async (accounts: AccountInfo[], instance: IPublicClientApplication, alertId: string) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let res = Axios.post(`${WAWS_ALERTING_API}/alert/createAMEApprovalLink/${alertId}`, null,
    {
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
        params: { 
            'token': accessToken, 'alias': emailToAlias(email) 
        }
    })
    .then((response) => { return response; })
    .catch((error) => { return error.response });
    return res;
}

export const getAlertPRStatus = async (accounts: AccountInfo[], instance: IPublicClientApplication, alertId: string, prLink: string) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let res = Axios.get(`${WAWS_ALERTING_API}/alert/getAlertPRStatus/${alertId}`,
    {
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
        params: { 
            'token': accessToken, 'alias': emailToAlias(email), 'prLink': prLink
        }
    })
    .then((response) => { return response })
    .catch((error) => { return error.response });
    return res;
}

export const getAMEApprovalStatus = async (accounts: AccountInfo[], instance: IPublicClientApplication, alertId: string, approvalDocumentId: string) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let res = Axios.get(`${WAWS_ALERTING_API}/alert/getAMEApprovalStatus/${alertId}`,
    {
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
        params: { 
            'token': accessToken, 'alias': emailToAlias(email), 'approvalDocumentId': approvalDocumentId
        }
    })
    .then((response) => { return response })
    .catch((error) => { return error.response });
    return res;
}

export const createAlert = async (accounts: AccountInfo[], instance: IPublicClientApplication, alert: IAlert) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
        const resp = Axios.post(`${WAWS_ALERTING_API}/alert`, alert,
        {
            headers: { "Content-Type": "application/json;", 'Authorization': bear },
            params: { 'token': accessToken, 'alias': emailToAlias(email) },
        }).then((response)=>{
            return response;
        }).catch((error)=>{
            return error.response;
        })
    return resp;
}

// Send GET request for a specific alert in detail page
export const fetchAlert = async (alertId: string, accounts: AccountInfo[], instance: IPublicClientApplication) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    const res = Axios.get<IAlert>(`${WAWS_ALERTING_API}/alert/${alertId}`, 
    { headers:{'Authorization': bear} }).then((response) => { 
        const data = response.data;
        if(data.secondaryKustoQueries && data.secondaryKustoQueries.length > 0){
            const sortedQueries = data.secondaryKustoQueries.map((query, index) => {
                return {
                    ...query,
                    position: index + 1
                }
            })
            data.secondaryKustoQueries = sortedQueries;
        }
        return data;
    });
    return res
};

export const switchAlert = async (accounts: AccountInfo[], instance: IPublicClientApplication, alertId: string, option: string) => {
  let email = accounts[0].username;
  let accessToken = await GetAccessToken(accounts, instance);
  let bear = `Bearer ${accessToken}`;
  let resp = Axios.post(`${WAWS_ALERTING_API}/alert/switch/${alertId}`, null,
    {
      headers:{'Authorization': bear},
      params: {'token': accessToken, 'alias': emailToAlias(email), 'option': option}
    }).then((response)=>{return response}).catch((error)=>{return error.response});
    return resp
}

export const deleteAlert = async (accounts: AccountInfo[], instance: IPublicClientApplication, alertId: string) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    let res = await Axios.delete(`${WAWS_ALERTING_API}/alert/${alertId}`,
        {
            headers: { "Content-Type": "application/json;", 'Authorization': bear },
            params: { 'token': accessToken, 'alias': emailToAlias(email) },
        }).then((response) => { return response }).catch((error)=>{return error.response});
    return res;
};

export const editAlert = async (accounts: AccountInfo[], instance: IPublicClientApplication, alert: IAlert, alertId: string) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
        const resp = await Axios.post(`${WAWS_ALERTING_API}/alert/${alertId}`, alert,
        {
            headers: { "Content-Type": "application/json;", 'Authorization': bear },
            params: { 'token': accessToken, 'alias': emailToAlias(email) },
        }).then((response) => { return response }).catch((error)=>{return error.response});
    return resp;
};

export function emailToAlias(email: string): string {
    return email.split("@", 1)[0];
}

export const runKustoQuery = async (accounts: AccountInfo[], instance: IPublicClientApplication, kustoQuery: string, clusters: string[], serviceId: string) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    let query : KustoQuery = {
        token: accessToken,
        primaryQuery: kustoQuery,
        alias: emailToAlias(email),
        clusters: clusters,
        serviceId: serviceId
    }
    let res = await Axios.post(`${WAWS_ALERTING_API}/alert/run_kusto`, query,
                    {
                    headers: {"Content-Type": "application/json;", 'Authorization': bear},
                    
                    }).then((response) => {return response.data});
    return res
}

export const getKustoResultsWithColumns = async (accounts: AccountInfo[], instance: IPublicClientApplication, kustoQuery: string, clusters: string[], serviceId: string) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    let query : KustoQuery = {
        token: accessToken,
        primaryQuery: kustoQuery,
        alias: emailToAlias(email),
        clusters: clusters,
        serviceId: serviceId
    }
    let res = await Axios.post(`${WAWS_ALERTING_API}/kusto_withcolumns`, query,
                    {
                    headers: {"Content-Type": "application/json;", 'Authorization': bear},
                    
                    }).then((response) => {return response.data}).catch((error)=>{return error.response});
    return res
}


export const machineActionKusto = async (accounts: AccountInfo[], instance: IPublicClientApplication, kustoQuery: string, clusters: string[], serviceId: string) => {
  let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    let query : KustoQuery = {
        token: accessToken,
        primaryQuery: kustoQuery,
        alias: emailToAlias(email),
        clusters: clusters,
        serviceId: serviceId
    }
    let res = await Axios.post(`${WAWS_ALERTING_API}/machineaction_kusto`, query,
      {
        headers: {"Content-Type": "application/json;", 'Authorization': bear},
      }).then((response)=>{return response}).catch((error)=>{return error.response});
    return res;
}

export const runPastAlert = async (accounts: AccountInfo[], instance: IPublicClientApplication, alertId: string, executionTime?: number) => {
  let email = accounts[0].username;
  let accessToken = await GetAccessToken(accounts, instance);
  let bear = `Bearer ${accessToken}`;
  let param : PastTestParam = {
    alias: email,
    executionTime: executionTime
  }
  await Axios.post(`${WAWS_ALERTING_API}/alert/test_past/${alertId}`, param,
    {
      headers: {"Content-Type": "application/json;", 'Authorization': bear}
    }).then((response)=>{return response.data});
}


export const fetchServices = async (accounts: AccountInfo[], instance: IPublicClientApplication, nameFilter: string, onboardStatus:string = "NotOnboarded", ownerFilter:string ="" ) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    console.log('searching the keyword: ', nameFilter);

    var res;
    if(onboardStatus === "Onboarded")
    {
        res = await Axios.get(`${WAWS_ALERTING_API}/service`,
        {
          headers:{'Authorization': bear},
            params: { 'token': accessToken, 'alias': emailToAlias(email), 'filter': nameFilter, "status": onboardStatus, "owner": ownerFilter },
        }).then(
            (response) => {
                return response.data
            }
        );
    }
    else
    {
        res = await Axios.get(`${WAWS_ALERTING_API}/service`,
        {
          headers:{'Authorization': bear},
            params: { 'token': accessToken, 'alias': emailToAlias(email), 'filter': nameFilter },
        }).then(
            (response) => {
                return response.data
            }
        );
    }

    let result: IServiceManagement[] = [];
    res.forEach(element => {
        let resdata: IServiceManagement = {
            id: element.id,
            serviceName: element.serviceName,
            description: element.description,
            owners: element.ownerList,
            alertapprovers: element.alertAproverList? element.alertAproverList: "",
            kustoclusters: element.kustoClusters.length,
            icmteams: element.routingTeamCount,
            //alerts: Math.floor(Math.random()*10).toString(),
            details: "detail",
            createdBy: ""
        };
        result.push(resdata);
    });

    return result;
}

export const fetchServiceById = async (accounts: AccountInfo[], instance: IPublicClientApplication, id: string) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    console.log('searching the service: ', id);

    const res = await Axios.get(`${WAWS_ALERTING_API}/service/${id}`,
        {
          headers:{'Authorization': bear},
            params: { 'token': accessToken, 'alias': emailToAlias(email), 'format': "Detail" },
        }).then(
            (response) => {
                return response.data
            }
        );
        console.log(res.item1.kustoClusters)
// owners: res.item1.ownerList, approvers: res.item1.alertAproverList
    return {
      id: res.item1.id,
      name: res.item1.serviceName,
      description: res.item1.description,
      owners: res.item1.ownerList,
      approvers: res.item1.alertAproverList,
      icmconnectorid: res.item1.icmConnectorId,
      alertDefaultDescription: res.item1.alertDefaultDescription,
      allowedAadGroups: res.item1.allowedAadGroupNames,
      genevaExtensions: res.item1.genevaExtensions,
      genevaRestrictedOperations: res.item1.genevaRestrictedOperations,
      kustoClusters: res.item1.kustoClusters,
      kustoAllTags: res.item1.kustoAllTags
    };
}

export const fetchKustosByServiceId = async (accounts: AccountInfo[], instance: IPublicClientApplication, id: string) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    console.log('searching the service: ', id);

    const res = await Axios.get(`${WAWS_ALERTING_API}/service/${id}`,
        {
          headers:{'Authorization': bear},
            params: { 'token': accessToken, 'alias': emailToAlias(email), 'format': "Detail" },
        }).then(
            (response) => {
                return response.data
            }
        );
        
    let tmpData = res.item1.kustoClusters.map(item => ({ cloud: item.cloud, description: item.description, cluster: item.cluster, database: item.database }))

    return tmpData;
}

export const TestKustoCluster = async (accounts: AccountInfo[], instance: IPublicClientApplication, body: string) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    let isConnected = false;
    await Axios.post(`${WAWS_ALERTING_API}/kusto/connect`, body,
        {
            headers: { "Content-Type": "application/json;", 'Authorization': bear },
            params: { 'token': accessToken, 'alias': emailToAlias(email) },
        }).then((result) => {
            isConnected = true;
        })
        .catch((error) => {
        });
    
    return isConnected;
}

export const updateService = async (accounts: AccountInfo[], instance: IPublicClientApplication, id: string, body: string, parameterName: string) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    let res1;
    await Axios.post(`${WAWS_ALERTING_API}/service/${id}`, body,
        {
            headers: { "Content-Type": "application/json;", 'Authorization': bear },
            params: { 'token': accessToken, 'alias': emailToAlias(email), 'property': parameterName },
        }).then((result) => {
            res1 = result;
        })
        .catch((error) => {
            res1 = error.response;
        })
    return res1;
}

export const fetchService = async (accounts: AccountInfo[], instance: IPublicClientApplication, serviceId: string) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    let service = Axios.get(`${WAWS_ALERTING_API}/service/${serviceId}`,
    {
      headers:{'Authorization': bear},
        params: {'token': accessToken, 'alias': emailToAlias(email)}
    }).then((response) => {return response.data});
    return service;
}

export const fetchServiceTeams = async (accounts: AccountInfo[], instance: IPublicClientApplication, available: boolean = false) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    let services = await Axios.get<IServiceManagement[]>(`${WAWS_ALERTING_API}/service`, 
    {
      headers:{'Authorization': bear},
        params: {'token': accessToken, 'alias': emailToAlias(email), 'status': "onboarded", 'available': available}
    })
    .then((response) => {return response.data});
    return services
}

export const fetchServiceIcm = async (accounts: AccountInfo[], instance: IPublicClientApplication, serviceId: string) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    let icmData = await Axios.get(`${WAWS_ALERTING_API}/icmservice/${serviceId}`, 
    {
      headers:{'Authorization': bear},
        params: {'token': accessToken, 'alias': emailToAlias(email)}
    })
    .then((response) => {return response.data});
    var teamRules = icmData.teamRules.sort((n1, n2)=>{
      if (n1.teamName > n2.teamName) {
        return 1
      } else {
        return -1;
      }
    });
    icmData.teamRules = teamRules;
    return icmData;
}

export const fetchServiceIcmCertUrl = async (accounts: AccountInfo[], instance: IPublicClientApplication) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    let certUrl = await Axios.get(`${WAWS_ALERTING_API}/icmcert`, 
    {
      headers:{'Authorization': bear},
        params: {'token': accessToken, 'alias': emailToAlias(email)}
    })
    .then((response) => {return response.data});
    if(certUrl === 'undefined' || !certUrl)
    {
        return "#";
    }
    else
    {
        return certUrl;
    }
}

export const fetchServiceKustos = async (accounts: AccountInfo[], instance: IPublicClientApplication, serviceId: string) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    let kustoData = await Axios.get(`${WAWS_ALERTING_API}/service/kustos/${serviceId}`, 
    {
      headers:{'Authorization': bear},
        params: {'token': accessToken, 'alias': emailToAlias(email)}
    }).then((response) => {return response.data});
    return kustoData;
}

export const fetchAreasIterations = async (projectName: string, accounts: AccountInfo[], instance: IPublicClientApplication) => {
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    let data = await Axios.get<WorkItemPaths>(`${WAWS_ALERTING_API}/alert/devops/classifications`, 
    {
        // use Antares as test data for now.
        headers:{'Authorization': bear},
        params: {'projectName': "Antares"}
    }
    ).then((response)=>{ return response.data});
    return data;
}

export const createWorkItem = async(accounts: AccountInfo[], instance: IPublicClientApplication, workItem: WorkItem) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    let item = await Axios.post(`${WAWS_ALERTING_API}/alert/devops/workitem`, workItem,
    {
      headers:{'Authorization': bear},
        params: {'token': accessToken, 'alias': emailToAlias(email)}
    }).then((response) => {return response.data});
    return item;
}

export const getTags = async(accounts: AccountInfo[], instance: IPublicClientApplication) => {
  let email = accounts[0].username;
  let accessToken = await GetAccessToken(accounts, instance);
  let bear = `Bearer ${accessToken}`;
  let tags = await Axios.get<string[]>(`${WAWS_ALERTING_API}/tags`, 
  {
    headers:{'Authorization': bear},
    params: {'token': accessToken, 'alias': emailToAlias(email)}
  }).then((response)=>{return response.data});
  return tags;
}

export const getServiceFilters = async (accounts: AccountInfo[], instance: IPublicClientApplication) => {
  let email = accounts[0].username;
  let accessToken = await GetAccessToken(accounts, instance);
  let bear = `Bearer ${accessToken}`;
  let resp = await Axios.get<any[]>(`${WAWS_ALERTING_API}/userservice`,
    {
      headers: {'Authorization': bear},
      params: {'token': accessToken, 'alias': emailToAlias(email)}
    }).then((response)=>{return response.data});
  return resp;
}

export const OnboardProductService = async(accounts: AccountInfo[], instance: IPublicClientApplication, serviceId: string) => {
    let email = accounts[0].username;
    let accessToken = await GetAccessToken(accounts, instance);
    let bear = `Bearer ${accessToken}`;
    let item = await Axios.post(`${WAWS_ALERTING_API}/service/onboard/${serviceId}`, null,
    {
      headers:{'Authorization': bear},
        params: {'token': accessToken, 'alias': emailToAlias(email)}
    }).then((response) => {return response});
    return item;
}

export const getMachineProtectionList = async(accounts: AccountInfo[], instance: IPublicClientApplication) : Promise<ProtectedMachine[]> => {
  let accessToken = await GetAccessToken(accounts, instance);
  let bear = `Bearer ${accessToken}`;
  let res = await Axios.get<ProtectedMachine[]>(`${WAWS_ALERTING_API}/protection/machines`,
    {
      headers: {'Authorization': bear}
    }).then((response)=> {return response});
    return res.data;
}

export const addProtectedMachine = async(accounts: AccountInfo[], instance: IPublicClientApplication, stamp: string, instanceStr: string) => {
  let email = accounts[0].username;
  let accessToken = await GetAccessToken(accounts, instance);
  let bear = `Bearer ${accessToken}`;
  let data: ProtectedMachine = {
    stamp: stamp,
    instance: instanceStr,
    createdBy: emailToAlias(email),
  }
  let res = await Axios.post(`${WAWS_ALERTING_API}/protection/add`, data,
  {
    headers: {'Authorization': bear}
  }).then((response)=>{return response});
  return res;
}

export const deleteProtectedMachine = async(accounts: AccountInfo[], instance: IPublicClientApplication, machine: ProtectedMachine) => {
  let accessToken = await GetAccessToken(accounts, instance);
  let bear = `Bearer ${accessToken}`;
  let res = await Axios.post(`${WAWS_ALERTING_API}/protection/delete/${machine.id}`, machine,
  {
    headers: {'Authorization': bear}
  }).then((response)=>{return response});
    return res;
}